























































































































































































































































import { Vue, Component, Prop, Watch, Ref } from 'vue-property-decorator'
import gql from 'graphql-tag'
import Loading from '@/components/Loading.vue'
import _mapValues from 'lodash/mapValues'
import draggable from 'vuedraggable'
import {
  CollectionFieldsFragment,
  CollectionFragment
} from '@/components/componentTypes/collections/fragments'
import { Collection, Field, FieldTypeDefinition } from '@/models'
import FieldTypes from '@/components/fields'
import Fields from '@/components/form/Fields.vue'
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import _startCase from 'lodash/startCase'
import cleanData from '@/utils/gql/cleanData'

const FieldIcons = _mapValues(FieldTypes, (f: any) => f.Icon) as Record<
  string,
  string
>

@Component({
  components: {
    Loading,
    Fields,
    draggable
  },
  apollo: {
    savedCollection: {
      query: gql`
        query getCollection($collectionId: ID) {
          savedCollection: collection(collectionId: $collectionId) {
            _id
            ...CollectionFields
          }
        }
        ${CollectionFieldsFragment}
      `,
      variables() {
        return {
          collectionId: this.collectionId
        }
      }
    },
    fieldTypes: gql`
      query {
        fieldTypes {
          _id
          name
          optionsParams
        }
      }
    `
  }
})
export default class CollectionEditFields extends Vue {
  @Prop({ type: String, required: true }) environmentId!: string
  @Prop({ type: String, required: true }) collectionId!: string

  savedCollection: Partial<Collection> | null = null
  collection: Partial<Collection> | null = null
  saving = false
  optionsSheetOpen: Record<string, any> = {}
  workingLabel: Field | null = null
  focusedIndex = -1

  fieldTypes: FieldTypeDefinition[] = []
  fieldErrors: Record<string, any> = {}

  @Watch('savedCollection')
  updateCollection() {
    this.collection = _cloneDeep(this.savedCollection)
    if (!this.collection) return
    if (!this.collection.fields) this.collection.fields = []
  }

  get tweaksEnabled() {
    return true
  }

  get fieldNameValidationRules() {
    return [
      (fName: string) => !!fName.trim() || 'Requerido.',
      (fName: string) => !!fName.match(/^[A-z][A-z\d]*$/) || 'Inválido',
      (fName: string) =>
        (this.collection?.fields?.filter((f) => f.name === fName) || [])
          .length <= 1 || 'Debe ser único.'
    ]
  }

  get fieldTypesWithIcons() {
    let fieldTypes = this.fieldTypes
    if (this.tweaksEnabled) {
      const commonFields = [
        'string',
        'number',
        'currency',
        'date',
        'datetime',
        'singleSelect',
        'multipleSelect',
        'oneOf',
        'manyOf',
        'boolean',
        'checkbox',
        'file'
      ]
      const common = commonFields
        .map((f) => this.fieldTypes.find((t) => t._id === f)!)
        .filter((f) => f)
      const others = this.fieldTypes.filter(
        (f) => !commonFields.includes(f._id)
      )
      fieldTypes = [
        { header: 'Tipos de campo comunes' } as unknown as FieldTypeDefinition,
        ...common,
        { header: 'Otros tipos de campo' } as unknown as FieldTypeDefinition,
        ...others
      ]
    }
    return fieldTypes.map((f) => ({
      ...f,
      icon: FieldIcons[f._id] || 'data_usage'
    }))
  }

  get valid() {
    if (!this.collection?.fields) return false
    return !this.collection.fields.some((f) => this.fieldErrors[f.name])
  }

  get dirty() {
    return !_isEqual(this.collection, this.savedCollection)
  }

  get showTip() {
    if (!this.focusedIndex) return false
    const activeField = this.collection?.fields?.[this.focusedIndex]
    return (
      this.tweaksEnabled &&
      this.collection?.fields?.length === this.focusedIndex + 1 &&
      activeField?.name
    )
  }
  get isFieldsOptionsValid() {
    const fields = this.collection?.fields
    if(!fields) return []
    const fieldsValid: Boolean[] = Array(fields.length).fill(true)
    let index = 0
    for(const field of fields) {
      const optionsParams = this.getTypeForField(field)?.optionsParams
      if(!optionsParams) {
        index++
        continue
      }
      for(const option in optionsParams) {
        if(optionsParams[option].optional) continue
        if(field.options[option]) continue
        fieldsValid[index] = false
      }
      index++
    }
    return fieldsValid
  }

  getTypeForField(field: Field) {
    if (!field || !field.type) return
    return this.fieldTypes.find((t) => t._id === field.type)
  }

/*   validateField(field: Field) {
    const validationResult = this.fieldNameValidationRules.find(
      (validate) => validate(field.name) !== true
    )
    this.fieldErrors[field.name] = !!validationResult
  }  */
  validateField(collection: Partial<Collection> | null) {
  const fieldErrors: Record<string, any> = {};

  if (this.collection?.fields) {
    this.collection.fields.forEach((field) => {
      const validationResult = this.fieldNameValidationRules.find(
        (validate) => validate(field.name) !== true
      );
      fieldErrors[field.name] = !!validationResult;
    });
  }

  this.fieldErrors = fieldErrors;
}




  handleFieldTypeChange(field: Field) {
    field.options = { options: [] }
  }

  handleInternalNameChange(field: Field, index: any) {
    if (this.tweaksEnabled) {
      if (!field.label || this.workingLabel === field) {
        this.workingLabel = field
        field.label = _startCase(field.name)
      }
    }
    this.validateField(field)
  }

  resetFocusedIndex() {
    this.focusedIndex = -1
    this.workingLabel = null
  }

  addField() {
    if (!this.collection) return
    if (!this.collection.fields) this.collection.fields = []
    this.collection.fields.push({
      name: '',
      label: '',
      // @ts-ignore
      type: this.tweaksEnabled ? 'string' : null,
      optional: true,
      options: { options: [] }
    })
  }

  removeField(field: Field) {
    if (!this.collection?.fields) return
    this.collection.fields = this.collection.fields.filter(
      (f) => f.name !== field.name
    )
  }

  openOptionsForField(field: Field) {
    this.$set(this, 'optionsSheetOpen', { [field.name]: true })
  }

  normalize() {
    if (!this.collection) return
    if (!this.collection.fields) this.collection.fields = []
    this.collection.fields = this.collection.fields.filter(
      (f) => f && f.name.trim() && f.type
    )
    this.collection?.fields?.forEach(this.validateField.bind(this))
  }

  async save() {
    if (!this.collection || this.saving) return
    this.normalize()
    if (!this.valid) return
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($collectionId: ID, $fields: [FieldInput]) {
            setCollectionFields(collectionId: $collectionId, fields: $fields) {
              ...Collection
            }
          }
          ${CollectionFragment}
        `,
        // Parameters
        variables: {
          collectionId: this.collection._id,
          fields: cleanData(this.collection, CollectionFieldsFragment).fields
        }
      })

      this.savedCollection = result.data.setCollectionFields
      this.$emit('save', result.data.updateFilter)
    } catch (e) {
      this.$emit('error', e)
      console.error(e)
    } finally {
      this.saving = false
    }
  }

  dismiss() {
    this.$emit('dismiss')
  }
}
