























import { Vue, Component, Prop } from 'vue-property-decorator'
import { Indicator, IndicatorResult } from '@/models'
import { IndicatorFragment } from './fragments'
import Loading from '@/components/Loading.vue'
import AnimatedNumber from '@/components/AnimatedNumber.vue'
import gql from 'graphql-tag'
import moment from '@/plugins/moment'
import CollectionWatcher from '@/components/tools/CollectionWatcher.vue'

@Component({
  components: {
    Loading,
    AnimatedNumber,
    CollectionWatcher
  },
  apollo: {
    indicatorResult: {
      query: gql`
        query indicatorResult(
          $indicatorId: ID
          $filterId: ID
          $filterOptions: JSON
          $params: JSON
        ) {
          indicatorResult(
            indicatorId: $indicatorId
            filterId: $filterId
            filterOptions: $filterOptions
            params: $params
          ) {
            value
            renderType
            renderFormat
            dependencies
          }
        }
      `,
      fetchPolicy: 'network-only',
      variables() {
        return {
          indicatorId: this.indicatorId,
          filterId: this.filterId,
          filterOptions: this.filterOptions,
          params: this.viewParams
        }
      }
    }
  }
})
export default class IndicatorResultView extends Vue {
  @Prop({ type: String }) indicatorId!: string
  @Prop({ type: String }) filterId?: string
  @Prop({ type: Object }) filterOptions?: Record<string, any>
  @Prop({ type: Object, default: () => ({}) }) viewParams!: Record<string, any>

  indicatorResult: IndicatorResult | null = null

  get numericFormat() {
    if (!this.indicatorResult) return false
    if (this.indicatorResult.renderType === 'number')
      return this.renderFormat || '0,0.[00]'
    if (this.indicatorResult.renderType === 'percentage')
      return this.renderFormat || '0.[00]%'
    if (
      this.indicatorResult.renderType === 'money' ||
      this.indicatorResult.renderType === 'currency'
    )
      return this.renderFormat || '$0,0.[00]'
    return false
  }

  get renderFormat() {
    if (!this.indicatorResult) return ''
    if (this.indicatorResult.renderFormat === 'number') return '0,0.[00]'
    if (this.indicatorResult.renderFormat === 'percentage') return '0.[00]%'
    if (
      this.indicatorResult.renderFormat === 'money' ||
      this.indicatorResult.renderFormat === 'currency'
    )
      return '$0,0.[00]'
    if (
      this.indicatorResult.renderFormat === 'date' ||
      this.indicatorResult.renderFormat === 'currency'
    )
      return 'DD-MM-YYYY'
    if (
      this.indicatorResult.renderFormat === 'datetime' ||
      this.indicatorResult.renderFormat === 'currency'
    )
      return 'DD-MM-YYYY HH:mm'
    return this.indicatorResult.renderFormat
  }

  renderDateValue(value: Date, format = 'DD-MM-YYYY') {
    return moment(value).format(format)
  }

  renderBooleanValue(
    value: boolean,
    trueString = 'Verdadero',
    falseString = 'Falso'
  ) {
    return value ? trueString : falseString
  }

  async update() {
    await this.$apollo.queries.indicatorResult.refetch()
  }

  get value() {
    if (!this.indicatorResult) return '...'
    switch (this.indicatorResult.renderType) {
      case 'boolean':
        return this.renderBooleanValue(this.indicatorResult.value)
      case 'date':
        return this.renderDateValue(
          this.indicatorResult.value,
          this.renderFormat || 'DD-MM-YYYY'
        )
      case 'datetime':
        return this.renderDateValue(
          this.indicatorResult.value,
          this.renderFormat || 'DD-MM-YYYY HH:mm'
        )
      default:
        return this.indicatorResult.value != null
          ? this.indicatorResult.value
          : '-'
    }
  }
}
