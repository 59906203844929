





















































































































import { Component, Prop, Vue, Watch, Provide } from 'vue-property-decorator'
import _snakeCase from 'lodash/snakeCase'
import resolveLink from '@/utils/route/resolveLink'
import gql from 'graphql-tag'
import { CollectionData, FormField, Table, TableFieldType } from '@/models'
import Field from '@/components/fields/Field.vue'
import IndicatorResult from '@/components/componentTypes/indicators/Result.vue'
import { ViewTableCell, ViewTableRow } from '.'
import openLink from '@/utils/route/openLink'
import BlockView from '@/components/componentTypes/blocks/View.vue'
import FormView from '@/components/componentTypes/forms/View.vue'
import { showDialog } from '@/components/dialogs'
import { GraphQLError } from 'node_modules/graphql'

@Component({
  components: {
    Field,
    IndicatorResult
  }
})
export default class TableField extends Vue {
  @Prop({ type: Object, required: true }) table!: Table
  @Prop({ type: Number, required: true }) fieldIndex!: number
  @Prop({ type: Boolean, default: false }) isSelected!: boolean
  @Prop({ type: Boolean, default: false }) isExpanded!: boolean
  @Prop({ type: Boolean, default: false }) preview!: boolean
  @Prop({ type: Object, required: true }) row!: ViewTableRow
  @Prop({ type: Object, required: true }) cell!: ViewTableCell
  @Prop({ type: Object, default: () => ({}) }) viewParams!: Record<string, any>

  @Provide() fieldValues() {
    return this.itemParams
  }

  busy = false
  saving = false
  pendingValue: any = null
  editing = false
  cancelling = false

  @Watch('cell', { immediate: true })
  updateSavedData() {
    this.pendingValue = this.cell.data
  }

  @Watch('editing')
  saveOnClose(value: boolean) {
    if (this.saving || this.cancelling || this.pendingValue === this.cell.data)
      return
    if (value === false && this.cell.options!.saveOnClickOutside)
      return this.saveValue()
  }

  @Watch('pendingValue')
  saveOnChange() {
    if (this.saving || this.cancelling || this.pendingValue === this.cell.data)
      return
    if (this.cell.options!.saveOnChange) return this.saveValue()
  }

  get selected() {
    if (this.cell.type === TableFieldType.SelectIconButton) {
      return (
        this.viewParams[this.cell.options!.variableTo] ===
        this.itemParams[this.cell.options!.variableFrom]
      )
    } else if (this.cell.type === TableFieldType.InlineBlock) {
      return this.isExpanded
    } else {
      return this.isSelected
    }
  }

  set selected(v: boolean) {
    this.$emit('toggleSelection', v)
  }

  get normalizedIcon() {
    let iconProp = 'icon'
    if (this.cell.type === TableFieldType.InlineBlock) {
      iconProp = this.isExpanded ? 'iconOpen' : 'iconClosed'
    }
    if (!this.cell.options || !this.cell.options[iconProp]) {
      if (this.cell.type === TableFieldType.InlineBlock) {
        return this.isExpanded ? 'expand_less' : 'expand_more'
      }
      return ''
    }
    const icon = this.cell.options[iconProp]
    return icon.startsWith('Md') ? _snakeCase(icon.slice(2)) : icon
  }

  get normalizedTooltip() {
    let tooltipProp = 'tooltip'
    if (this.cell.type === TableFieldType.InlineBlock) {
      tooltipProp = this.isExpanded ? 'tooltipOpen' : 'tooltipClosed'
    }
    if (!this.cell.options || !this.cell.options[tooltipProp]) {
      if (this.cell.type === TableFieldType.InlineBlock) {
        return this.isExpanded ? 'Contraer' : 'Expandir'
      }
      return ''
    }
    return this.cell.options[tooltipProp]
  }

  /** Combined View and Item params */
  get itemParams(): Record<string, any> {
    return {
      ...this.viewParams,
      _id: this.row.raw._id,
      ...this.row.raw.data
    }
  }

  /** Target Route (link buttons only) */
  get targetRoute() {
    if (this.cell.type !== TableFieldType.RouteIconButton) return {}
    return this.targetPath
  }

  get targetPath() {
    if (!this.cell.options || !this.cell.options.viewPath) return {}
    const { options } = this.cell

    const params: Record<string, string> = {}
    Object.keys(options.variableMap || {}).forEach((paramName) => {
      params[paramName] =
        this.itemParams[options.variableMap[paramName]] || 'undefined'
    })

    const toPath = resolveLink(options.viewPath, params)

    return {
      to: toPath.external ? undefined : toPath.href,
      href: toPath.external ? toPath.href : undefined,
      target: options.newTab ? '_blank' : undefined
    }
  }

  executeAction() {
    switch (this.cell.type) {
      case TableFieldType.DeleteRowByUser:
        return this.deleteItem()
      case TableFieldType.PostItem:
        return this.postItem()
      case TableFieldType.RunHooks:
        return this.runHooks()
      case TableFieldType.Modal:
        return this.openModal()
      case TableFieldType.SelectIconButton:
        if (this.selected) {
          this.$emit('singleSelect', {
            [this.cell.options!.variableTo]: undefined
          })
        } else {
          this.$emit('singleSelect', {
            [this.cell.options!.variableTo]:
              this.itemParams[this.cell.options!.variableFrom]
          })
        }
        return
      case TableFieldType.InlineBlock:
        this.$emit(this.isExpanded ? 'collapse' : 'expand')
        return
      default:
        return
    }
  }

  openModal() {
    const modalComponent = (
      {
        block: BlockView,
        form: FormView
      } as Record<string, any>
    )[this.cell.options!.componentType]

    return showDialog({
      id: `${this.cell.options!.componentType}-${
        this.cell.options!.componentId
      }`,
      component: modalComponent,
      wrapInCard: true,
      props: {
        environmentId: this.table.environmentId,
        componentId: this.cell.options!.componentId,
        itemDefinition: {
          id: this.cell.options!.componentId,
          type: this.cell.options!.componentType,
          sizeSmall: '12',
          sizeLarge: '12',
          blockId:
            this.cell.options!.componentType === 'block' &&
            this.cell.options!.componentId,
          formId:
            this.cell.options!.componentType === 'form' &&
            this.cell.options!.componentId,
          namespace: this.cell.options!.componentType + 's'
        },
        preview: false,
        showCloseButton: true,
        class: `md-${this.cell.options!.componentType}-${
          this.cell.options!.componentId
        }`,
        viewParams: {
          ...this.viewParams,
          ...this.itemParams
        }
      },
      dismissable: true,
      handler(result: boolean) {}
    })
  }

  async deleteItem() {
    if (this.busy) return
    if (this.cell.options!.modalText) {
      if (!confirm(this.cell.options!.modalText)) return
    }
    this.busy = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation tableDeleteItem(
            $tableId: ID
            $fieldIndex: Float
            $itemId: ID
          ) {
            tableDeleteItem(
              tableId: $tableId
              fieldIndex: $fieldIndex
              itemId: $itemId
            )
          }
        `,
        variables: {
          tableId: this.table._id,
          itemId: this.row.raw._id,
          fieldIndex: this.fieldIndex
        }
      })
      this.$emit('update')
    } catch (e) {
      let message = e.message
      if (e.graphQLErrors)
        message = e.graphQLErrors.map((e: GraphQLError) => e.message).join(', ')
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: message,
        color: 'error',
        timeout: 10000
      })
      console.error(e)
    } finally {
      this.busy = false
    }
  }

  async saveValue() {
    if (this.saving) return
    this.editing = false
    this.busy = true
    this.saving = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation tableUpdateItemField(
            $tableId: ID
            $fieldIndex: Float
            $itemId: ID
            $newValue: JSON
          ) {
            tableUpdateItemField(
              tableId: $tableId
              fieldIndex: $fieldIndex
              itemId: $itemId
              newValue: $newValue
            )
          }
        `,
        variables: {
          tableId: this.table._id,
          itemId: this.row.raw._id,
          fieldIndex: this.fieldIndex,
          newValue: {
            value: this.pendingValue
          }
        }
      })
      this.$emit('update')
    } catch (e) {
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Error: ' + e.message,
        color: 'error'
      })
      console.error(e)
    } finally {
      this.saving = false
      this.busy = false
    }
  }

  cancelEdit() {
    this.cancelling = true
    this.editing = false
    this.cancelling = false
  }

  // Reset selection if a selected fields exits out of the view
  beforeDestroy() {
    if (this.cell.type === TableFieldType.SelectIconButton && this.selected) {
      this.$emit('singleSelect', {
        [this.cell.options!.variableTo]: undefined
      })
    }
  }

  async postItem() {
    if (this.busy || !this.cell.options) return
    if (this.cell.options.modalText) {
      if (!confirm(this.cell.options.modalText)) return
    }
    this.busy = true
    try {
      const response = await fetch(this.cell.options.url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.itemParams)
      })
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Se completó con éxito'
      })
      console.log(
        `Item data posted to ${
          this.cell.options.url
        }, response: ${await response.text()}`
      )
    } catch (e) {
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Error: ' + e.message,
        color: 'error'
      })
      console.error(e)
    } finally {
      this.busy = false
    }
  }

  async runHooks() {
    if (this.busy) return
    if (this.cell.options!.modalText) {
      if (!confirm(this.cell.options!.modalText)) return
    }
    this.busy = true
    try {
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation tableRunHooks(
            $tableId: ID
            $fieldIndex: Float
            $itemId: ID
          ) {
            tableRunHooks(
              tableId: $tableId
              fieldIndex: $fieldIndex
              itemId: $itemId
            )
          }
        `,
        variables: {
          tableId: this.table._id,
          itemId: this.row.raw._id,
          fieldIndex: this.fieldIndex
        }
      })
      this.$emit('update')
      if (this.targetPath && (this.targetPath.to || this.targetPath.href)) {
        await openLink(
          this.targetPath.to || this.targetPath.href || '',
          {
            ...this.itemParams,
            ...result.data.tableRunHooks
          },
          this.cell.options!.newTab
        )
      }
      return this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Se completó con éxito'
      })
    } catch (e) {
      let message = e.message
      if (e.graphQLErrors)
        message = e.graphQLErrors.map((e: GraphQLError) => e.message).join(', ')
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: message,
        color: 'error',
        timeout: 10000
      })
      console.error(e)
    } finally {
      this.busy = false
    }
  }
}
